import { Theme } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { themeColors } from '../../../../theme'
export const useStyles = makeStyles()((theme: Theme) => ({
  messaging__tabs: {
    listStyle: 'none',
    margin: 0,
    paddingLeft: 10,
  },
  messaging__tab: {
    display: 'inline-block',
    marginRight: '1rem',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
  },
  messaging__tab__active: {
    height: '5px',
    width: '100%',
    background: themeColors.blue,
    borderRadius: '6px',
    marginTop: '6px',
  },
  messaging__list: {
    marginTop: '1rem',
    display: 'flex',
    flexDirection: 'column',
    height: '92%',
    overflowY: 'scroll',
  },
  message: {
    position: 'relative',
    padding: theme.spacing(2),
    display: 'grid',
    gridTemplateColumns: '4rem 1fr 110px',
    gridTemplateRows: '1.25rem 3rem 1fr',
    gridColumnGap: '0.4rem',
    gridRowGap: '0px',
    border: '1px solid',
    borderColor: themeColors.gray60,
    borderRadius: '0.5rem',
    marginBottom: '1.5rem',
  },
  message__isPreview: {
    borderColor: 'transparent',
    marginBottom: 0,
    cursor: 'pointer',
    position: 'relative',
    background: themeColors.gray90,

    '&:hover, &:hover::before': {
      background: themeColors.blue90,

      message__detailsIcon: {
        visibility: 'hidden',
      },
    },
  },
  message__isPreviewFromYou: {
    background: themeColors.blue80,
  },
  message__icon: {
    height: '50px',
    width: '50px',
    marginLeft: '-8px',
    backgroundColor: themeColors.gray40,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    fontWeight: 'bold',
    placeSelf: 'center',
  },
  message__icon__image: {
    backgroundSize: 'cover',
  },
  message__providers: {
    fontSize: '13px',
    fontWeight: 'bold',
    maxWidth: '100%',
    maxHeight: '40px',
  },
  message__timestamp: {
    gridArea: '1 / 3 / 3 / 5',
    fontSize: '12px',
    textAlign: 'right',
    color: themeColors.gray40,
  },
  message__preview: {
    marginTop: '4px',
    fontSize: '13px',
    border: '1px solid',
    borderColor: themeColors.blue80,
    background: themeColors.gray90,
    borderRadius: '0.5rem',
    padding: 5,
    justifyContent: 'center',
  },
  message__flags: {
    marginTop: '0.77rem',
  },
  message__toList: {
    color: themeColors.gray60,
    fontSize: '0.75rem',
  },
  message__toList__to: {
    fontWeight: 'bold',
    color: themeColors.gray10,
  },
  message__urgent: {
    color: themeColors.red92,
    fontSize: '0.75rem',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  message__unread: {
    position: 'absolute',
    left: 0,
    top: '50%',
    height: '0.7rem',
    width: '0.7rem',
    borderRadius: '50%',
    background: themeColors.blue,
  },
  message__text: {
    gridArea: '4 / 1 / 4 / 4',
    fontSize: '14px',
  },
  message__detailsIcon: {
    marginLeft: '10px',
  },
  msgThread__actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    borderRadius: '6px',
  },
  msgThread__actions__btn: {
    display: 'inline-block',
    marginLeft: '0.5rem',
  },
  msgThread__back: {
    fontSize: '14px',
    color: themeColors.blue,
    paddingTop: '0.3125rem',
  },
  msgThread__back__arrow: {
    marginTop: '0.25rem',
    marginRight: '7px',
  },
  msgThread__replyBox: {
    width: '100%',
    border: '1px solid black',
  },
  msgThread__attachment: {
    fontSize: '13px',
    color: themeColors.grayblack,
    width: 'auto',
    height: 'auto',
    backgroundColor: themeColors.lightPurple,
    marginRight: 10,
    marginBottom: 5,
    borderRadius: '.5rem',
    padding: '.25rem .75rem',
    alignSelf: 'center',
  },
  msgCreate: {
    padding: theme.spacing(2),
    border: '1px solid',
    borderColor: themeColors.gray60,
    borderRadius: '0.5rem',
    marginBottom: '1.5rem',
  },
  msgCreate__label: {
    color: themeColors.gray60,
    fontSize: '0.75rem',
    width: '2.5rem',
    flexShrink: 0,
  },
  msgCreate__userRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: '.5rem',
  },
  msgCreate__user: {
    fontSize: '0.75rem',
    marginBottom: '0.2rem',
    borderRadius: '0.3rem',
  },
  msgCreate__userList: {
    borderBottom: '1px solid',
    borderColor: themeColors.gray70,
    flexGrow: 1,
    position: 'relative',
  },
  msgCreate__userRow__actions: {
    width: '2.5rem',
    textAlign: 'right',
    flexShrink: 0,
  },
  msgCreate__userRow__priorityBtn: {
    fontSize: '0.75rem',
    textAlign: 'center',
  },

  msgReply_box_wrapper: {
    width: '100%',
    height: '100%',
    border: '1px solid',
    borderColor: themeColors.gray90,
    borderRadius: '0.5rem',
    padding: '10px 20px',
  },
  msgRecipient_selected_wrapper: {
    zIndex: 99,
    width: 'auto',
    maxWidth: '60%',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  msgRecipient_toLine: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-end',
    position: 'relative',
    flexWrap: 'wrap',
  },
  msgRecipient_toLine_textarea: {
    outline: 'none',
    border: 'none',
    resize: 'none',
    zIndex: 1,
    minWidth: '40%',
    height: '1.75rem',
    overflow: 'hidden',
    marginBottom: '0.25rem',
  },
  msgRecipient_tag: {
    width: '100%',
    border: 'none',
    background: 'transparent',
    padding: '12px 20px 0px 20px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'stretch',
    '&:hover': {
      background: themeColors.blue90,
    },
  },
  msgRecipient_tag_avatar: {
    width: '3.125rem',
    height: '3.125rem',
    marginBottom: '12px',
    background: themeColors.gray40,
    backgroundSize: 'cover',
    borderRadius: '50%',
    color: 'white',
    fontSize: '1rem',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  msgRecipient_tag_left: {
    width: '33%',
  },
  msgRecipient_tag_right: {
    width: '66%',
    borderBottom: '1px solid ' + themeColors.gray70,
    textAlign: 'left',
    alignItems: 'center',
    paddingBottom: '12px',
  },
  msgRecipient_tag_subtext: {
    color: themeColors.gray40,
  },
  msgRecipient__dropdown: {
    display: 'flex',
    flexDirection: 'column',
    width: '16rem',
    background: 'white',
    borderRadius: '0.25rem',
    borderWidth: '2px',
    borderColor: themeColors.grayblack,
    maxHeight: '15rem',
    overflowY: 'scroll',
    position: 'absolute',
    top: '100%',
    zIndex: '99',
    marginTop: '0.5rem',
    padding: '0.15rem',
    boxShadow: '0px 5px 10px -4px rgba(0, 0, 0, 0.25)',
  },
  msgAttachments_preview_wrapper: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxWidth: '100%',
  },
  msgFrom_To_chip: {
    width: 'auto',
    borderColor: themeColors.gray60,
    cursor: 'pointer',
    zIndex: '99',
    marginRight: 10,
    marginBottom: 5,
  },
  rich_editor_link_popup: {
    height: 300,
  },
  messageThread_backBtn: {
    height: '30px',
    width: '30px',
    border: 'none',
    borderRadius: 5,
    backgroundColor: themeColors.gray90,
    marginLeft: 10,
  },
}))
