import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@mui/material'
import { blue } from '@mui/material/colors'
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridFilterModel,
} from '@mui/x-data-grid'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useEffect, useState } from 'react'

import { Patient, PatientList } from '../../../../providers/Patients/types'
import { useProviderDetails } from '../../../../providers/ProviderDetails/ProviderDetails.provider'
import { themeColors } from '../../../../theme'
import { formatPatientsForTable } from '../utilities'

dayjs.extend(customParseFormat)

const COLUMNS: GridColDef[] = [
  { field: 'preferredName', headerName: 'Name', width: 200 },
  { field: 'mrn', headerName: 'MRN', width: 160 }, // MRN
  // { field: 'isActive', headerName: 'Is Active', width: 160 }, // MRN
  {
    field: 'deactivationDate',
    headerName: 'Deactivation Date',
    width: 170,
    type: 'string',
  },
  { field: 'accessRoles', headerName: 'Program', width: 110 }, //'access role' is called 'patient program' on the UI
].map((col) => ({ ...col, disableColumnMenu: true }))

// NCA-43: 'preferredName', 'mrn', 'deactivationDate'
const OPTIONS = COLUMNS.filter((col: GridColDef) =>
  ['preferredName', 'mrn', 'deactivationDate', 'accessRoles'].includes(
    col.field
  )
).map((col: GridColDef) => col.headerName)

interface ListViewProps {
  patients: PatientList
  handleDeactivatePatientModal: (patientId: number) => void
  handleReactivatePatientModal: (patientId: number) => void
  selectedPatient: Patient | null
  editPatientModal: boolean
  setEditPatientModal: React.Dispatch<React.SetStateAction<boolean>>
  myPatientsSelected: boolean
  setMyPatientsSelected: React.Dispatch<React.SetStateAction<boolean>>
  handleEditPatientModal: (patientId: number) => void
  isEditLoading: boolean
  handleRowClick: (patientId: number) => void
}

export const ListView = ({
  patients,
  selectedPatient,
  handleEditPatientModal,
  isEditLoading,
  handleRowClick,
  myPatientsSelected,
  setMyPatientsSelected,
}: ListViewProps) => {
  const { myProviderDetails } = useProviderDetails()

  const [searchField, setSearchField] = useState<string | null>('Name')
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null)
  const [filterModel, setFilterModel] = useState<GridFilterModel>({ items: [] })
  const [dataColumns, setDataColumns] = useState<GridColDef[]>(COLUMNS)
  const [pageSize, setPageSize] = useState<number>(10)

  const handleMyPatientsClicked = () => {
    setMyPatientsSelected(true)
  }

  const handleAllPatientsClicked = () => {
    setMyPatientsSelected(false)
  }

  useEffect(() => {
    if (searchField && searchKeyword) {
      setFilterModel({
        items: [
          {
            columnField: dataColumns.find(
              (dc) => dc.headerName === searchField
            )!.field,
            operatorValue: 'contains',
            value: searchKeyword,
          },
        ],
      })
    } else {
      setFilterModel({ items: [] })
    }
  }, [searchField, searchKeyword])

  useEffect(() => {
    if (patients) {
      const dataColumns = COLUMNS.map((col) => {
        if (col.field === 'preferredName') {
          return {
            ...col,
            renderCell: (params: GridCellParams) => {
              const cellPatient = patients.find(
                (p) => p.patientId === params.id
              )
              return (
                <Box sx={{ minWidth: '100%', display: 'flex' }}>
                  {cellPatient?.needsReview ? (
                    <div
                      style={{
                        // position: 'relative',
                        // left: 0,
                        marginTop: '10px',
                        height: '10px',
                        width: '15px',
                        borderRadius: '100%',
                        background: themeColors.darkRed,
                      }}
                    >
                      <p style={{ color: 'transparent' }}>*</p>
                    </div>
                  ) : (
                    ''
                  )}
                  <Button
                    sx={{
                      height: '100%',
                      overflowWrap: 'wrap',
                      minWidth: '100%',
                    }}
                    variant='text'
                    onClick={async () => {
                      await handleRowClick(Number(params.id))
                    }}
                  >
                    <Typography
                      sx={{
                        color: themeColors.blue40,
                        fontSize: '14px',
                        maxWidth: '100%',
                        wordBreak: 'break-word',
                        whiteSpace: 'normal',
                        textAlign: 'left',
                        minWidth: '100%',
                      }}
                    >
                      {params.value}
                    </Typography>
                  </Button>
                </Box>
              )
            },
          }
        } else {
          return col
        }
      })
      if (
        myProviderDetails?.accessRoles![0].description === 'SuperAdmin' ||
        myProviderDetails?.accessRoles![0].description === 'Admin'
      ) {
        const deactivationDate = dataColumns.find(
          (column) => column.field === 'deactivationDate'
        )
        const accessRoles = dataColumns.find(
          (column) => column.field === 'accessRoles'
        )
        dataColumns.splice(2, 2, {
          field: ' ',
          headerName: ' ',
          width: 150,
          renderCell: (params: GridCellParams) =>
            isEditLoading &&
            selectedPatient &&
            selectedPatient.patientId === Number(params.id) ? (
              <CircularProgress
                size={20}
                style={{
                  justifySelf: 'center',
                  alignSelf: 'left',
                  margin: 'auto',
                }}
              />
            ) : (
              <Button
                id={`edit-role-btn-${Number(params.id)}`}
                sx={{
                  minWidth: '100%',
                }}
                variant='contained'
                color='warning'
                onClick={() => {
                  handleEditPatientModal(Number(params.id))
                }}
              >
                Edit Patient Program
              </Button>
            ),
        })
        if (accessRoles && deactivationDate) {
          dataColumns.splice(2, 0, accessRoles)
          dataColumns.splice(2, 0, deactivationDate)
        }
      }

      setDataColumns(dataColumns)
    }
  }, [patients, isEditLoading, selectedPatient])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '10px',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '125%',
          }}
        >
          Patient View
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginBottom: '10px',
        }}
      >
        <div style={{ margin: '0px 10px 0px 0px' }}>
          <Button
            onClick={handleMyPatientsClicked}
            variant={myPatientsSelected ? 'contained' : 'outlined'}
            color='warning'
          >
            My Patients
          </Button>
        </div>
        <Button
          onClick={handleAllPatientsClicked}
          variant={!myPatientsSelected ? 'contained' : 'outlined'}
          color='warning'
        >
          All Patients
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '20px 0 30px',
          width: '675px',
        }}
      >
        <Box sx={{ width: '225px' }}>
          <Typography
            sx={{
              fontWeight: 700,
              fontSize: '14px',
              marginBottom: '5px',
            }}
          >
            SEARCH BY
          </Typography>
          <Autocomplete
            options={OPTIONS}
            sx={{ width: 'auto' }}
            onChange={(_: any, data: any) => {
              setSearchField(data)
              setSearchKeyword(null)
            }}
            value={searchField}
            renderInput={(params: any) => (
              <TextField
                {...params}
                size='small'
                variant='outlined'
                id='SearchByField'
                placeholder='Select field to search by'
              />
            )}
          />
        </Box>
        {searchField && (
          <Box sx={{ width: '425px' }}>
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '14px',
                marginBottom: '5px',
              }}
            >
              KEYWORD
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TextField
                size='small'
                variant='outlined'
                sx={{ height: '35px', width: '325px' }}
                onChange={(e) => setSearchKeyword(e.currentTarget.value)}
                value={searchKeyword || ''}
                placeholder={`Type here to search the table below by ${searchField.toLowerCase()}`}
              />
              <Box>
                <Button
                  variant='outlined'
                  sx={{ minWidth: '75px', marginLeft: '20px' }}
                  onClick={() => {
                    setSearchKeyword(null)
                    setSearchField(null)
                  }}
                >
                  Clear
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={{ width: '100%', display: 'flex', height: '100%' }}>
        <Box sx={{ width: '100%', flexGrow: 1, height: '459px' }}>
          <DataGrid
            getRowId={(row: any) => row.patientId}
            rows={patients.map((pg: Patient) => formatPatientsForTable(pg))}
            columns={dataColumns}
            pageSize={pageSize}
            rowsPerPageOptions={[10, 25, 50]}
            onPageSizeChange={(v) => setPageSize(v)}
            rowHeight={70}
            filterModel={filterModel}
            disableColumnFilter={true}
            isRowSelectable={() => false}
            sx={{
              fontFamily: 'Lato',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '100%',
              verticalAlign: 'center',
              border: 'none',
              '& .MuiDataGrid-cell:active, .MuiDataGrid-cell:focus, .MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:hover':
                {
                  border: 'none',
                  outline: 'none',
                },
              '& .MuiTableCell-root': {
                border: 'none',
                outline: 'none',
              },
              '& .MuiDataGrid-cell:hover': {
                pointer: 'cursor',
              },
              '& .MuiDataGrid-columnHeaderTitleContainer, .MuiDataGrid-columnHeaderTitleContainer:focus, .MuiDataGrid-columnHeaderTitleContainer:focus-within':
                {
                  fontWeight: 700,
                  fontSize: '14px',
                  lineHeight: '100%',
                  textTransform: 'uppercase',
                  border: 'none',
                  outline: 'none',
                },
              '& .MuiDataGrid-iconButtonContainer svg': {
                color: themeColors.blue,
              },
              '& .MuiDataGrid-iconSeparator': {
                display: 'none',
              },
              '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-columnHeader:focus-within':
                {
                  border: 'none',
                  outline: 'none',
                },
              '& .MuiDataGrid-row.even': {
                backgroundColor: themeColors.gray99,
              },
              '& .MuiDataGrid-row.even:hover, .MuiDataGrid-row.odd:hover': {
                backgroundColor: themeColors.blue90,
              },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: 'preferredName', sort: 'asc' }],
              },
            }}
            getRowClassName={(params) =>
              params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
            }
            components={{
              NoResultsOverlay: () => (
                <Box
                  sx={{
                    background: themeColors.white,
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 700,
                      fontSize: '14px',
                      color: themeColors.blue,
                    }}
                  >
                    NO RESULTS
                  </Typography>
                </Box>
              ),
            }}
          ></DataGrid>
        </Box>
      </Box>
    </Box>
  )
}
