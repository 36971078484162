import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'

import { useAxios } from '../../utilities/Requests/useAxios'
import { AccessRoleList } from '../ProvidersProvider/types'
import { Patient, PatientList } from './types'

// NEW INTERFACE
export interface PatientsContextInterface {
  patients: PatientList | null
  myPatients: PatientList | null
  getPatients: () => void
  getMyPatients: () => void
  deactivatePatient: (
    patientMRN: string,
    deactivationDate: Date
  ) => Promise<any>
  reactivatePatient: (patientMRN: string) => Promise<any>
  patientAccessRoles: AccessRoleList | null
  updatePatientAccessRole: (
    patientId: number,
    accessRoleId: number
  ) => Promise<void>
}

// EXPORT CONTEXT NEW
export const PatientsContext = createContext<PatientsContextInterface | null>(
  null
)

// NEW PATIENTS PROVIDER
export const PatientsProvider = ({ children }: { children?: ReactNode }) => {
  const [patients, setPatients] = useState(null)
  const [myPatients, setMyPatients] = useState(null)

  const [patientAccessRoles, setPatientAccessRoles] = useState(null)

  const { fetch } = useAxios()

  // NCA-43: Get patients data for patients table, waiting on api migrations
  const getPatients = useCallback(async () => {
    const { data } = await fetch({
      path: 'Provider/GetListOfPatients', // look at network tab to see the results
    })
    if (data) {
      //  to ensure every item to finish, use Promise.all()
      const result = data.map((p: Patient) => {
        const nullDeactivationDate = '0001-01-01'
        const deactivationDate =
          p.deactivationDate.toString() === nullDeactivationDate
            ? null
            : p.deactivationDate
        return {
          ...p,
          deactivationDate,
        }
      })
      setPatients(result)
    }
  }, [])

  const getMyPatients = useCallback(async () => {
    const { data } = await fetch({
      path: 'Provider/GetListOfMyPatients', // look at network tab to see the results
    })
    if (data) {
      //  to ensure every item to finish, use Promise.all()
      const result = data.map((p: Patient) => {
        const nullDeactivationDate = '0001-01-01'
        const deactivationDate =
          p.deactivationDate.toString() === nullDeactivationDate
            ? null
            : p.deactivationDate
        return {
          ...p,
          deactivationDate,
        }
      })
      setMyPatients(result)
    }
  }, [])

  useEffect(() => {
    getPatients()
  }, [])

  useEffect(() => {
    getMyPatients()
  }, [])

  // NCA-78 Call 'DeactivatePatient' service
  // Remove patient from all peer groups and set isActive status to False.
  const deactivatePatient = useCallback(
    async (patientMRN: string, deactivationDate: Date) => {
      const { error } = await fetch({
        path: `Provider/DeactivatePatient?patientMRN=${patientMRN}&deactivationDate=${
          deactivationDate.toISOString().split('T')[0]
        }`,
        methodType: 'POST',
      })
      console.log(error)
      if (error) {
        throw new Error(`Error in deactivating patient.`)
      }
    },
    []
  )

  const getPatientAccessRoles = useCallback(async () => {
    const { data } = await fetch({
      path: 'Provider/GetPatientAccessRoles',
    })

    if (data) {
      setPatientAccessRoles(data)
    }
  }, [])

  useEffect(() => {
    getPatientAccessRoles()
  }, [])

  const reactivatePatient = useCallback(async (patientMRN: string) => {
    const { error } = await fetch({
      path: `Provider/ReactivatePatient?patientMRN=${patientMRN}`,
      methodType: 'POST',
    })
    if (error) {
      throw new Error(`Error in reactivating patient.`)
    }
  }, [])

  const updatePatientAccessRole = useCallback(
    async (patientId: number, accessRoleId: number) => {
      const { error } = await fetch({
        methodType: 'POST',
        path: `Provider/UpdatePatientAccessRole?PatientId=${patientId}&AccessRoleId=${accessRoleId}`,
      })

      await getPatients()

      if (error) {
        console.log('updatePatientAccessRole-error', error)
        throw new Error(`Error in updating patient access role.`)
      }
    },
    []
  )

  return (
    <PatientsContext.Provider
      value={{
        patients,
        getPatients,
        myPatients,
        getMyPatients,
        deactivatePatient,
        reactivatePatient,
        updatePatientAccessRole,
        patientAccessRoles,
      }}
    >
      {children}
    </PatientsContext.Provider>
  )
}

// NEW EXPORT
export const usePatients = () =>
  useContext(PatientsContext) as PatientsContextInterface
