import { Box } from '@mui/material'
import { useState } from 'react'

import { Patient, PatientList } from '../../../providers/Patients/types'
import { AccessRoleList } from '../../../providers/ProvidersProvider/types'
import { DeactivatePatientModal } from './partials/DeactivatePatient.partial'
import { EditPatientModal } from './partials/EditPatientModal'
import { ListView } from './partials/ListView.partial'
import { ReactivatePatientModal } from './partials/ReactivatePatient.partial'
import { ViewType } from './types'

interface PatientsViewProps {
  viewMode: ViewType
  setViewMode: React.Dispatch<React.SetStateAction<ViewType>>
  patients: PatientList | null
  showDeactivatePatientModal: boolean
  setShowDeactivatePatientModal: React.Dispatch<React.SetStateAction<boolean>>
  handleDeactivatePatientModal: (selectedPatientID: number) => void
  handleDeactivatePatient: (
    selectedPatient: Patient,
    selectedDate: Date
  ) => Promise<boolean>
  // Reactivate Patient
  showReactivatePatientModal: boolean
  setShowReactivatePatientModal: React.Dispatch<React.SetStateAction<boolean>>
  handleReactivatePatientModal: (selectedPatientID: number) => void
  handleReactivatePatient: (selectedPatient: Patient) => Promise<boolean>
  editPatientModal: boolean
  setEditPatientModal: React.Dispatch<React.SetStateAction<boolean>>
  handleEditPatientModal: (selectedPatientID: number) => void
  handleEditPatientAccess: (
    selectedPatient: Patient,
    accessRoleId: number
  ) => Promise<void>
  selectedPatient: Patient | null
  // setPeerGroupId: React.Dispatch<React.SetStateAction<number | null>>
  // peerGroupId: number | null
  providerEmail: string | null
  patientAccessRoles: AccessRoleList | null
  handleRowClick: (patientId: number) => void
  myPatientsSelected: boolean
  setMyPatientsSelected: React.Dispatch<React.SetStateAction<boolean>>
}

export const PatientsView = ({
  viewMode,
  patients,
  showDeactivatePatientModal,
  setShowDeactivatePatientModal,
  handleDeactivatePatientModal,
  handleDeactivatePatient,
  // Reactivate Patient
  showReactivatePatientModal,
  setShowReactivatePatientModal,
  handleReactivatePatientModal,
  handleReactivatePatient,
  selectedPatient,
  patientAccessRoles,
  editPatientModal,
  setEditPatientModal,
  handleEditPatientModal,
  handleEditPatientAccess,
  handleRowClick,
  myPatientsSelected,
  setMyPatientsSelected,
}: PatientsViewProps) => {
  const [isEditLoading, setIsEditLoading] = useState<boolean>(false)

  return (
    <Box>
      {selectedPatient && (
        // NCA-41: Created box for modal 'Deactivate Patient'
        <>
          <div className='modal-container'>
            <div className='deactivate-modal'>
              <DeactivatePatientModal
                selectedPatient={selectedPatient}
                showDeactivatePatientModal={showDeactivatePatientModal}
                setShowDeactivatePatientModal={setShowDeactivatePatientModal}
                handleDeactivatePatient={handleDeactivatePatient}
              />
            </div>
            <div className='reactivate-modal'>
              <ReactivatePatientModal
                selectedPatient={selectedPatient}
                // Reactivate Patient
                showReactivatePatientModal={showReactivatePatientModal}
                setShowReactivatePatientModal={setShowReactivatePatientModal}
                handleReactivatePatient={handleReactivatePatient}
              />
            </div>
          </div>
          <div className='modal-container'>
            <div className='deactivate-modal'>
              <EditPatientModal
                selectedPatient={selectedPatient}
                editPatientModal={editPatientModal}
                setEditPatientModal={setEditPatientModal}
                patientAccessRoles={patientAccessRoles}
                handleEditPatientAccess={handleEditPatientAccess}
                setIsEditLoading={setIsEditLoading}
              />
            </div>
          </div>
        </>
      )}
      {viewMode === ViewType.List && patients && (
        <ListView
          patients={patients}
          handleDeactivatePatientModal={handleDeactivatePatientModal}
          handleReactivatePatientModal={handleReactivatePatientModal}
          selectedPatient={selectedPatient}
          editPatientModal={editPatientModal}
          setEditPatientModal={setEditPatientModal}
          handleEditPatientModal={handleEditPatientModal}
          isEditLoading={isEditLoading}
          handleRowClick={handleRowClick}
          myPatientsSelected={myPatientsSelected}
          setMyPatientsSelected={setMyPatientsSelected}
        />
      )}
    </Box>
  )
}
